:root {
  --header-color: rgba(22, 29, 32, 1);
  --label-color: rgba(22, 29, 32, 1);
  --color-0: rgba(49, 125, 136, 1);
  --color-1: rgba(13, 148, 136, 1);
  --color-2: rgba(13, 148, 136, 0.8);
  --color-3: rgba(118, 195, 188, 0.5);
  --color-4: rgba(118, 195, 188, 0.4);
  --color-5: rgba(118, 195, 188, 0.2);
  --color-6: rgba(118, 195, 188, 0.15);
  --color-7: rgba(118, 195, 188, 0.1);
  --color-8: rgba(118, 195, 188, 0.01);
  --color-hover: rgba(49, 125, 136, 0.15);
  --swal-green: rgba(13, 148, 136, 1);
  --swal-orange: rgba(255, 212, 112, 1);
  --swal-red: rgba(255, 105, 105, 1);
  --black: rgba(0, 0, 0, 1);
  --red: rgba(202, 79, 79, 1);
  --red-hover: rgba(149, 62, 62, 1);
  --orange: rgba(253, 197, 93, 1);
  --creation: rgba(173, 216, 230, 1);
  --pending: rgba(253, 197, 93, 1);
  --waiting-list: rgba(56, 157, 224, 1);
  --approved: rgba(82, 190, 157, 1);
  --admitted: rgba(135, 206, 250, 1);
  --discharged: rgba(255, 160, 122, 1);
  --rejected: rgba(255, 69, 80, 1);
  --text-color: #333;
  --light-gray: rgba(245, 245, 245, 1);

  /* 
  --header-color: rgba(22, 29, 32, 1);
  --label-color: rgba(22, 29, 32, 1);
  --color-0: rgba(20, 75, 124, 1);
  --color-1: rgba(28, 121, 201, 1);
  --color-2: rgba(28, 121, 201, 0.8);
  --color-3: rgba(118, 176, 233, 0.5);
  --color-4: rgba(118, 176, 233, 0.4);
  --color-5: rgba(118, 176, 233, 0.2);
  --color-6: rgba(118, 176, 233, 0.15);
  --color-7: rgba(118, 176, 233, 0.1);
  --color-8: rgba(118, 176, 233, 0.01);
  --color-hover: rgba(20, 75, 124, 0.15);
  --swal-green: rgba(13, 148, 136, 1);
  --swal-orange: rgba(255, 212, 112, 1);
  --swal-red: rgba(255, 105, 105, 1);
  --black: rgba(0, 0, 0, 1);
  --green: rgba(82, 190, 157, 1);
  --red: rgba(202, 79, 79, 1);
  --red-hover: rgba(149, 62, 62, 1);
  --orange: rgba(253, 197, 93, 1);
  --creation: rgba(173, 216, 230, 1);
  --pending: rgba(253, 197, 93, 1);
  --waiting-list: rgba(56, 157, 224, 1);
  --approved: rgba(82, 190, 157, 1);
  --admitted: rgba(135, 206, 250, 1);
  --discharged: rgba(255, 160, 122, 1);
  --rejected: rgba(255, 69, 80, 1);
  --text-color: #333;
  --light-gray: rgba(245, 245, 245, 1);
  */
}
