/* You can add global styles to this file, and also import other style files */
@import "ng-zorro-antd/ng-zorro-antd.min.css";
@import "./colors.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

/* MODALS */
.hiddenCanvasElement {
  display: none;
}

.ant-modal {
  min-width: 520px !important;
  width: 50% !important;
}

.ant-btn {
  color: black;
  background: var(--color-4) !important;
  border-color: var(--color-1) !important;
}

.ant-layout {
  background: #f4f4f6 !important;
}

.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background: #f4f4f6 !important;
  padding: 0px !important;
}

.ant-select-selector {
  height: 100% !important;
}

/* ADD & EDIT FORMS */
label {
  font-weight: 500;
}

::ng-deep input {
  padding: 0 11px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 100%;
}

/* .inputs input {
  padding: 0 11px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 100%;
} */

input.inputs {
  padding: 0 11px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 100%;
}

.section-header {
  margin-bottom: 1em;
  padding-left: 3em !important;
}

.inputs {
  width: 100%;
}

.ant-btn.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}

.ant-btn.cancel-btn:hover {
  background: lightgray !important;
}

.ant-btn:focus {
  color: black;
}

.ant-btn:hover {
  color: black;
  background: var(--color-3) !important;
}

.material-icons {
  font-size: 22px;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

/* BUTTONS */
.add-btn {
  margin-bottom: 1em;
  margin-top: 1em;
  padding-right: 1em;
}

.back-button {
  cursor: pointer;
}

.remove-btn {
  cursor: pointer;
}

.delete-btn {
  cursor: pointer;
}

.save-btn {
  margin-right: 1em;
}

.remove-btn {
  color: var(--color-1) !important;
}

.remove-btn:hover {
  cursor: pointer;
}

/* TABLES */
.footer {
  margin-top: 2em;
}

.title {
  padding-left: 1em;
  padding-right: 1em;
}

.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* MARGINS & PADDINGS & WIDTHS */
.margin-lr {
  margin-left: 1em;
  margin-right: 1em;
}

.margin-llr {
  margin-left: 2em;
  margin-right: 1em;
}

.margin-lrr {
  margin-left: 1em;
  margin-right: 0.5em;
}

.w-100 {
  width: 100% !important;
}

/* FORMS */
.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.table-margin {
  margin-bottom: 2em;
}

.add-icon {
  margin-right: 0.2em;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

nz-layout {
  background: #f4f4f6;
}

nz-select {
  margin-left: 0em !important;
}

nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}

form {
  border: none !important;
}

.form-row {
  margin-top: 1em;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}

nz-embed-empty {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-picker {
  margin-left: 1em !important;
}

.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}

@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}

.left-margin {
  margin-left: 1em;
}

.material-icons {
  font-size: 22px;
}

.no-known-content {
  display: flex !important;
}

.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}

.ant-switch-checked {
  background: var(--color-1) !important;
}

.ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}

.ant-pagination-item-active {
  border-color: black !important;
}

.table-header.ant-layout-header {
  background-color: var(--color-6) !important;
  border: 1px solid #d8d8d8 !important;
}

/* PHONE COMPONENTS */
.iti {
  width: 100%;
}

.iti input:disabled {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: rgba(239, 239, 239, 0.3) !important;
  width: 100%;
}

.iti input {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: transparent !important;
  width: 100%;
}

ngx-intl-tel-input {
  width: 100%;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

/* EXPAND COMPONENTS */
.detail-row {
  border-bottom: 1px solid #f0f0f0;
}

.detail-info {
  background-color: white !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  border-radius: 4px;
}

.detail-label {
  background-color: white !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  border-radius: 4px;
}

.mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}

.mdc-circular-progress__determinate-circle {
  stroke: rgb(13, 148, 136) !important;
}

.mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(13, 148, 136) !important;
}
